import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"
import Hero from "../components/Hero"
import KeyFacts from "../components/KeyFacts"
import Seo from "../components/Seo"
import UpcomingEvents from "../components/strapiDynamicZone/UpcomingEvents"
import LatestNews from "../components/strapiDynamicZone/LatestNews"

const IndexPage = () => {
  const {
    strapiHome: { metaPageTitle, metaPageDesc },
  } = useStaticQuery(query)
  const news = {
    title: "news",
    maxNewsArticles: 3,
    showMoreButton: true,
  }
  const events = {
    title: "upcoming events",
    maxRecords: 5,
    showMoreButton: true,
  }

  return (
    <Layout>
      <Seo title={metaPageTitle} description={metaPageDesc} />
      <Hero />
      <KeyFacts />
      <LatestNews values={news} />
      <UpcomingEvents values={events} />
    </Layout>
  )
}

export default IndexPage

const query = graphql`
  {
    strapiHome {
      metaPageTitle
      metaPageDesc
    }
  }
`
