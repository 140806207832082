import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, graphql, useStaticQuery } from "gatsby"

import SocialLinks from "../constants/socialLinks"

const query = graphql`
  {
    strapiHome {
      title
      tagLine
      buttonText
      buttonUrl
      vision
      heroImage {
        alternativeText
        caption
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

const Hero = () => {
  const {
    strapiHome: { title, tagLine, buttonText, buttonUrl, heroImage, vision },
  } = useStaticQuery(query)

  const image = getImage(heroImage.localFile)

  return (
    <Wrapper>
      <header className="hero">
        <article className="hero-info">
          <div>
            <h1 className="hero-title">{title}</h1>
            <div className="hero-proverb">{tagLine}</div>
            <Link to={buttonUrl} className="button outline-btn">
              {buttonText}
            </Link>
            <SocialLinks styleClass="hero-social" />
          </div>
        </article>
        <GatsbyImage
          image={image}
          alt={heroImage.alternativeText}
          className="hero-img"
        />
      </header>
      <div className="vision">{vision}</div>
    </Wrapper>
  )
}

export default Hero

const Wrapper = styled.section`
  .hero {
    padding-top: 8rem;
    height: 70vh;
    position: relative;
    background-image: linear-gradient(
      rgba(51, 51, 51, 0.4),
      rgba(51, 51, 51, 0.4)
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .hero-info {
    padding: 5rem 3rem;
    text-align: center;
  }
  h1.hero-title {
    font-family: var(--ff-primary);
    color: var(--clr-white);
    text-align: center;
    font-size: 8rem;
  }
  .hero-proverb {
    color: var(--clr-white);
    padding-bottom: 3rem;
    text-align: center;
    font-style: italic;
    font-size: 1.8rem;
  }
  .hero-img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -100;
  }
  .hero-social {
    margin: 2rem auto;
  }

  .hero .social-link {
    color: var(--clr-white);
  }

  .vision {
    text-align: center;
    font-size: 2.5rem;
    font-style: italic;
    margin: 5rem 0;
    padding: 0 6rem;
    color: var(--clr-grey-3);
  }

  @media screen and (min-width: 760px) {
    .hero h4 {
      font-size: 1.36rem;
    }
  }
  @media screen and (min-width: 1170px) {
    .hero h4 {
      font-size: 1.6rem;
    }
    .hero h4 {
      line-height: 1;
    }
  }
`
