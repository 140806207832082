import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReadMoreLink from "./UI/ReadMoreLink"

const PageCard = ({
  id,
  title,
  slug,
  rootUrl,
  metaPageDesc,
  teaserImage,
  visibility,
  showDate,
  published_at,
}) => {
  const linkTo = rootUrl ? `/${rootUrl}/${slug}/` : `./${slug}/`
  const image = teaserImage && getImage(teaserImage.localFile)

  let teaserText = metaPageDesc.trim().substring(0, 100)
  if (teaserText.length === 100) {
    teaserText = teaserText.substring(0, teaserText.lastIndexOf(" ")) + " ..."
  }

  if (visibility !== "internal") {
    return (
      <Wrapper>
        <div className="card" key={id}>
          {teaserImage && (
            <GatsbyImage
              image={image}
              className="card-img"
              alt="teaser image"
            />
          )}

          <div className="card-text">
            {showDate ? (
              <div className="published">{published_at.toUpperCase()}</div>
            ) : null}
            <h4>{title}</h4>
            <p>{teaserText}</p>
            <ReadMoreLink url={linkTo} />
          </div>
        </div>
      </Wrapper>
    )
  } else {
    return null
  }
}

const Wrapper = styled.article`
  .card {
    display: block;
    margin-bottom: 3.2rem;
    background: var(--clr-primary-7);
    box-shadow: var(--light-shadow);
    transition: var(--transition);
    font-weight: normal;
    text-decoration: none;
    position: relative;
    min-height: 18rem;
  }
  .card:hover {
    box-shadow: var(--dark-shadow);
    transform: scale(1.02);
  }
  .card-img {
    height: 18rem;
    opacity: 0.15;
  }
  .card-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: var(--clr-primary-1);
    text-align: center;
    padding: 1rem 1.5rem;
  }
  .card-text h4 {
    font-size: 3rem;
  }
  .card-text p {
    display: none;
  }

  @media screen and (min-width: 576px) {
    .card-image {
      border-radius: var(--radius);
    }
    .card {
      border-radius: var(--radius);
    }
    .card-text h4 {
      font-size: 2.4rem;
    }
  }

  @media print, screen and (min-width: 760px) {
    .card-img {
      height: 25rem;
      border-radius: var(--radius);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 0.8rem solid var(--clr-contrast);
      opacity: 1;
    }
    .card {
      background: var(--clr-white);
      display: flex;
      flex-direction: column;
    }
    .card-text {
      position: relative;
      display: block;
      transform: translate(0, 0);
      top: 0;
      left: 0;
      color: var(--clr-grey-2);
      text-align: left;
      margin-top: 1.5rem;
      & h4 {
        color: var(--clr-grey-1);
        font-size: 2rem;
      }
      & p {
        display: block;
        color: var(--clr-grey-3);
      }
    }
    .published {
      color: var(--clr-grey-4);
      margin-bottom: 1.6rem;
    }
  }
`

export default PageCard
