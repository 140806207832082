import React from "react"
import styled from "styled-components"
import { FaArrowCircleRight } from "react-icons/fa"
import { Link } from "gatsby"

const ReadMoreLink = ({ url, styleClass }) => (
  <Wrapper>
    <Link to={url} style={styleClass}>
      <div className="container">
        <div className="readmore-icon">
          <FaArrowCircleRight />
        </div>
        <div className="readmore-text">Read more</div>
      </div>
    </Link>
  </Wrapper>
)

export default ReadMoreLink

const Wrapper = styled.div`
  display: inline-block;

  .container {
    display: flex;
    align-items: top;
    color: var(--clr-contrast);
    :hover {
      text-decoration: underline;
    }
  }

  .readmore-icon {
    padding: 0.2rem 1rem 0 0;
  }
  .readmore-text {
    font-size: 1.8rem;
  }
`
