import React from "react"
import styled from "styled-components"
import PageCard from "./PageCard"
import { Link } from "gatsby"

export const PageCards = ({
  pages,
  title,
  showLink,
  rootUrl,
  showDate,
  maxCards,
}) => {
  let cardsDisplayed = 0
  return (
    <Wrapper className="section">
      {title && <h2 className="section-title">{title}</h2>}
      <div className="cards-center">
        {pages.map(page => {
          cardsDisplayed += 1
          if (maxCards && cardsDisplayed > maxCards) {
            return null
          } else {
            return (
              <PageCard
                key={page.id}
                showDate={showDate}
                rootUrl={rootUrl}
                {...page}
              />
            )
          }
        })}
      </div>
      {showLink && (
        <div className="btn-container">
          <Link to={`/${rootUrl}/`} className="button center-btn">
            more
          </Link>
        </div>
      )}
    </Wrapper>
  )
}

export default PageCards

const Wrapper = styled.section`
  & {
    margin-top: 2rem;
    padding: 5rem 0;
    background-color: var(--clr-grey-7);
  }
  .btn-container {
    text-align: center;
    margin-top: 2rem;
  }

  @media print, screen and (min-width: 576px) {
    padding: 5rem;
    .cards-center {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
      column-gap: 3.2rem;
    }
  }
`
