import React from "react"
import styled from "styled-components"
import ReadMoreLink from "./UI/ReadMoreLink"
import {
  FaBookOpen,
  FaCheck,
  FaChild,
  FaGlobe,
  FaGraduationCap,
  FaHeart,
  FaLanguage,
  FaMapMarkerAlt,
  FaMosque,
} from "react-icons/fa"
import { graphql, useStaticQuery, Link } from "gatsby"

const query = graphql`
  {
    facts: allStrapiKeyFacts(sort: { fields: sort, order: ASC }) {
      nodes {
        strapiId
        title
        body
        icon
        hexColor
        url
      }
    }
  }
`

const getIcon = icon => {
  let renderedIcon = ""
  switch (icon) {
    case "FaBookOpen":
      renderedIcon = <FaBookOpen />
      break
    case "FaCheck":
      renderedIcon = <FaCheck />
      break
    case "FaChild":
      renderedIcon = <FaChild />
      break
    case "FaGlobe":
      renderedIcon = <FaGlobe />
      break
    case "FaGraduationCap":
      renderedIcon = <FaGraduationCap />
      break
    case "FaHeart":
      renderedIcon = <FaHeart />
      break
    case "FaLanguage":
      renderedIcon = <FaLanguage />
      break
    case "FaMapMarkerAlt":
      renderedIcon = <FaMapMarkerAlt />
      break
    case "FaMosque":
      renderedIcon = <FaMosque />
      break
    default:
      renderedIcon = <FaHeart />
  }
  return renderedIcon
}

const KeyFacts = () => {
  const {
    facts: { nodes: facts },
  } = useStaticQuery(query)

  return (
    <div className="page-margins">
      <Wrapper>
        {/* <h2 className="section-title">Key Facts</h2> */}
        <div className="facts">
          {facts.map((fact, index) => {
            return (
              <div className="fact" key={index}>
                <div className="icon">{getIcon(fact.icon)}</div>
                <div className="details">
                  {fact.url ? (
                    <Link to={fact.url} className="fact-title">
                      <h3>{fact.title}</h3>
                    </Link>
                  ) : (
                    <h3>{fact.title}</h3>
                  )}

                  <p className="fact-text">{fact.body}</p>
                  {fact.url && <ReadMoreLink url={fact.url} />}
                </div>
              </div>
            )
          })}
        </div>
      </Wrapper>
    </div>
  )
}

export default KeyFacts

const Wrapper = styled.section`
  & {
    margin: 4rem 0;
  }
  .fact {
    display: flex;
    margin-bottom: 2rem;
    background: var(--clr-grey-7);
    box-shadow: var(--light-shadow);
    border-radius: var(--radius-large);
  }
  .icon {
    display: block;
    font-size: 4rem;
    text-align: center;
    color: var(--clr-primary-1);
    background-color: var(--clr-grey-6);
    border-right: 1px solid var(--clr-primary-1);
    padding: 2rem 1rem;
    border-top-left-radius: var(--radius-large);
    border-bottom-left-radius: var(--radius-large);
    overflow: hidden;
    min-width: 10rem;
  }
  .details {
    padding: 2rem;
  }
  .more-link {
    font-weight: normal;
    padding-top: 1rem;
  }

  @media screen and (min-width: 576px) {
    .facts {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
      column-gap: 3.2rem;
    }
  }
`
