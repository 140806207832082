import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"

const UpcomingEvents = ({ values }) => {
  const {
    upcomingEvents: { nodes: calendarEvents },
  } = useStaticQuery(query)
  let eventsDisplayed = 0

  return (
    <Wrapper>
      {values.title ? <h2 className="section-title">{values.title}</h2> : null}
      <div className="event-grid">
        {calendarEvents.map(thisEvent => {
          eventsDisplayed += 1
          if (eventsDisplayed > values.maxRecords) {
            return null
          } else {
            let dateFrom = thisEvent.from
            let dateTo = ""
            if (thisEvent.to) {
              dateFrom += " - "
              dateTo = thisEvent.to
            }

            return (
              <div key={thisEvent.id} className="event-row">
                <div className="date-column">
                  {dateFrom} {dateTo}
                </div>
                <div className="text-column">{thisEvent.title}</div>
              </div>
            )
          }
        })}
      </div>
      {values.showMoreButton ? (
        <div className="btn-container">
          <Link to="/calendar/" className="button">
            more
          </Link>
        </div>
      ) : null}
    </Wrapper>
  )
}

export default UpcomingEvents

const Wrapper = styled.section`
  padding: 5rem 2rem;

  .btn-container {
    text-align: center;
    padding: 3rem 0;
  }

  .event-row {
    display: flex;
    width: 100%;
  }
  .event-row:nth-child(even) {
    background-color: var(--clr-grey-7);
  }
  .date-column {
    width: 40%;
    padding: 1rem 0.5rem;
  }
  .text-column {
    padding: 1rem 2rem;
    margin-left: 5rem;
  }
  @media screen and (min-width: 576px) {
    padding: 5rem;
  }
`

const query = graphql`
  {
    upcomingEvents: allStrapiEvents(
      sort: { fields: from, order: ASC }
      filter: { isFuture: { eq: true } }
    ) {
      nodes {
        from(formatString: "ll")
        to(formatString: "ll")
        title
        id
      }
    }
  }
`
