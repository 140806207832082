import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PageCards from "../PageCards"

const LatestNews = ({ values }) => {
  const {
    news: { nodes: news },
  } = useStaticQuery(query)
  return (
    <PageCards
      pages={news}
      title={values.title}
      maxCards={values.maxRecords}
      rootUrl="news"
      showLink={values.showMoreButton}
      showDate
    />
  )
}

export default LatestNews

const query = graphql`
  {
    news: allStrapiArticles(sort: { fields: [published_at], order: [DESC] }) {
      nodes {
        published_at(formatString: "ll")
        slug
        id
        title
        metaPageDesc
        teaserImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
